<template>
  <div class="supplydemand">
    <div class="supplydemand-left">
      <div class="supplydemand-left-header">
        <div class="info_header_left">我收藏的企业</div>
        <div class="message-totle">共 {{ total }} 条</div>
      </div>

      <div class="user-list-box">
        <div v-if="total == 0" class="empty-box">
          <div class="img-empty-wrap">
            <!-- <i class="el-icon-document"></i> -->
          </div>
          <div class="content-slot-wrap">这里什么都没有</div>
        </div>
        <div
          v-else
          class="Company-details-item"
          v-for="item in myCompanylist"
          @click="goCompanyDetails(item)"
        >
          <div class="company-left">
            <img :src="item.company.logo" alt="" />
          </div>
          <div class="company-right">
            <div class="company_name">
              <p>{{ item.company.name }}</p>
              <div @click.stop="clickComoanyFav(item.conferenceCompanyId)">
                <span class="compant_shoucang">
                  <img :src="imgIcon.imgCangTrue" />
                  <span>已收藏</span>
                </span>

                <!-- <span class="count">{{ item.read }}</span> -->
              </div>
            </div>
            <!-- <div class="type-box">
            <div class="type">品牌</div>
            <div class="type-item">{{ item.company.conferenceCompanyTypeName }}</div>
          </div> -->
            <!-- <div class="Sub-box">
            <span v-for="items in item.dt_Exhibits" class="Sub-label">{{
              items.name
            }}</span>
          </div> -->
            <p v-if="item.company.address">
              公司地址：{{ item.company.address }}
            </p>
            <p @click.stop="goUrl(item.company.website)">
              公司官网：{{ item.company.website }}
            </p>
          </div>
          <!-- <div class="text-operation">
           
            <div @click.stop="clickComoanyFav(item.conferenceCompanyId)">
              <img :src="imgIcon.imgCang" />
              <span v-if="item.fav != 0">{{ item.fav }}</span
              ><span v-else>收藏</span>
            </div>
          </div> -->
        </div>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getDTConferenceCompanyFavList,
  companyLike,
  companyLikeDown,
  companyFav,
  companyFavDown,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "supplydemand",
  data() {
    return {
      queryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        conferenceId:25,
        userId: localStorage.getItem("userId") - 0,
      },
      conferenceCompanyId: 0,
      myCompanylist: [],
      classifylist: [],
      resultlist: [],
      currentPage: 1,
      peopletype: "最新加入",
      Supplylist: [],
      total: 0,
      hotsupplylist: [],
      dialogVisible: false,
      imgIcon: imgObj,
    };
  },
  methods: {
    getCompanyList() {
      getDTConferenceCompanyFavList(this.queryParams).then((res) => {
        console.log("企业", res);
        this.myCompanylist = res.data.data.item1;
        this.total = res.data.data.item2;
      });
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    showrelease() {
      let token = window.localStorage.getItem("token");
      let username = window.localStorage.getItem("userName");

      this.$router.push("/release");

      // if (token && username) {
      //   this.$router.push("/release");
      // } else {
      //   this.$message({
      //     message: "请先登录后，才能发布供需",
      //     type: "warning",
      //   });
      //   setTimeout(() => {
      //     location.reload();
      //   }, 1000);
      // }
    },
    goCompanyDetails(item) {
      let routeData = this.$router.resolve({
        name: "companyDetail",
        query: {
          id: item.companyId,
          conferenceCompanyId: item.conferenceCompanyId,
          title: item.name,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goUrl(url) {
      window.open(`//${url}`, "_blank");
    },
    getFav() {
      fav().then((res) => {
        console.log(res);
        this.Supplylist = res.data;
        // this.total = this.Supplylist.length;
      });
    },
    handleSizeChange(e) {
      console.log(e);
    },
    handleCurrentChange(e) {
      console.log(e);
    },
    changefirstbtn(item, index) {
      this.classifytext = item.title;
      this.resultlist = this.classifylist[index].children;
      this.resulttext = "全部";
      this.changelist(item.id);
    },
    changesecondbtn(item) {
      this.resulttext = item.title;
      this.changelist(item.id);
    },
    changelist(id) {
      getSupplyclassifyid(id).then((res) => {
        this.Supplylist = res.data;
        this.total = this.Supplylist.length;
      });
    },
    clickCompanyLike(companyId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: 4,
          conferenceCompanyId: companyId,
        };
        companyLike(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            companyLikeDown(data).then((res) => {
              this.getCompanyList();
            });
          } else {
            this.getCompanyList();
          }
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
    },
    clickComoanyFav(companyId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId:localStorage.getItem("userId") - 0,
          conferenceCompanyId: companyId,
        };

        companyFavDown(data).then((res) => {
          this.getCompanyList();
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`);
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id,
          type,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.getCompanyList();
  },
};
</script>

<style lang="less" scoped>
.supplydemand {
  //   width: 1280px;
  padding: 0.625rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  .supplydemand-left {
    width: 100%;
    margin-right: 20px;
    .supplydemand-left-header {
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
    }
    .message-totle {
      // padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }
    .user-list-box {
      .empty-box {
        padding: 4rem 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-empty-wrap {
          display: block;
          width: 180px;
          height: 180px;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          // font-size: 65px;
          color: #8a919f;
          background-image: url("https://mcnki.obs.cn-east-2.myhuaweicloud.com/img/empty.png");
        }
        .content-slot-wrap {
          font-size: 14px;
          font-weight: 400;
          color: #8a919f;
        }
      }
      padding: 20px;
      background: white;
      .Company-details-item {
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
        background: white;
        display: flex;
        align-items: center;
        cursor: pointer;
        .company-left {
          img {
            width: 100px;
          }
        }
        .company-right {
          margin-left: 30px;
          width: 100%;
          .company_name {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            .compant_shoucang {
              display: flex;
              align-items: center;
              border: 0.0625rem solid #ededed;
              padding: 0.6rem 1.2rem;
              background: #fff;
              color: #525252;
              font-size: 0.875rem;
              font-weight: 400;
              margin: auto;
            }
            div {
              // padding: 10px 25px;
              // background: #fff;
              color: #525252;
              font-size: 14px;
              margin-right: 20px;
              // border: 1px solid #ededed;
              display: flex;
              align-items: flex-end;
              span {
                margin-left: 10px;
                min-width: 44px;
              }
            }
          }
          p:nth-child(1) {
            font-size: 20px;
            margin: 0;
            margin-bottom: 10px;
          }
          p:nth-child(2) {
            margin-top: 10px;
            font-size: 11px;
            margin-bottom: 10px;
          }
          p:nth-child(3) {
            margin-top: 10px;
            font-size: 11px;
            margin-bottom: 10px;
          }
          .type-box {
            display: flex;
            font-size: 12px;
            .type {
              background: #314483;
              padding: 3px 10px;
              text-align: center;
              color: white;
              line-height: 20px;
            }
            .type-item {
              padding: 3px 10px;
              line-height: 20px;
              text-align: center;
              background: white;
              color: #314483;
              border: 1px solid #314483;
              margin-left: 10px;
            }
          }
          .Sub-box {
            display: flex;
            font-size: 11px;
            margin-top: 10px;
            .Sub-label {
              padding: 3px 10px;
              line-height: 20px;
              text-align: center;
              background: #e2e2e2;
              margin-right: 15px;
            }
          }
        }
        .text-operation {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          // div:nth-child(1) {
          //   position: absolute;
          //   left: 0px;
          // }
          // .iconfont {
          //   font-size: 20px;
          //   color: #a8a8a8;
          //   margin-left: 10px;
          //   margin-right: 5px;
          //   cursor: pointer;
          // }
          div {
            padding: 10px 25px;
            background: #fff;
            color: #525252;
            font-size: 14px;
            margin-right: 20px;
            border: 1px solid #ededed;
            display: flex;
            align-items: flex-end;
            span {
              margin-left: 5px;
            }
          }
        }
      }
      .Company-details-item:hover {
        background: #e9e9e973;
      }
    }
  }

  .el-pagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #314483;
    }
    /deep/ li:hover {
      color: white;
      background: #314483;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #314483;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>